// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FabricRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "flatback",
    "name": "Flatback mesh"
  },
  {
    "id": "honeycomb",
    "name": "Honeycomb"
  },
  {
    "id": "pinhole",
    "name": "Pinhole mesh"
  },
  {
    "id": "lycra",
    "name": "Performance lycra"
  },
  {
    "id": "pinholeWomen",
    "name": "Pinhole stretch mesh"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FabricRow[]>> = data;

export type Fabric = typeof typed[number];

export const FABRIC_INDEX_KEY = "id";
export type FabricIndexKey = "id";
export type FabricId = Fabric["id"];

let i = 0;
export const FABRIC_DICT = {
  "flatback": typed[i++],
  "honeycomb": typed[i++],
  "pinhole": typed[i++],
  "lycra": typed[i++],
  "pinholeWomen": typed[i++]
} as const;

export { typed as FABRICS };
