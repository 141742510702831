// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { DesignRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "freeze",
    "name": "Freeze",
    "colors": 2,
    "limitations": {
      "productIds": [
        "womensShortSleeve",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "nails",
    "name": "Nails",
    "colors": 3,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "nitro",
    "name": "Nitro",
    "colors": 4,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "turbo",
    "name": "Turbo",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "athena",
    "name": "Athena",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "attack",
    "name": "Attack",
    "colors": 3,
    "limitations": {
      "productIds": [
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "champ",
    "name": "Champ",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank"
      ]
    }
  },
  {
    "id": "rebel",
    "name": "Rebel",
    "colors": 3,
    "limitations": {
      "productIds": [
        "mensTank",
        "mensSleeveless",
        "womensTank"
      ]
    }
  },
  {
    "id": "socal",
    "name": "Socal",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "redondo",
    "name": "Redondo",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "impact",
    "name": "Impact",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "laser",
    "name": "Laser",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "malibu",
    "name": "Malibu",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "titan",
    "name": "Titan",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "blaze",
    "name": "Blaze",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "beach",
    "name": "Beach",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensTank"
      ]
    }
  },
  {
    "id": "hermosa",
    "name": "Hermosa",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "elevate",
    "name": "Elevate",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "watersprings_alt",
    "name": "Watersprings Alt",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "ace",
    "name": "Ace",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "wave",
    "name": "Wave",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensTank",
        "mensSleeveless",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "diamond",
    "name": "Diamond",
    "colors": 3,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "sash",
    "name": "Sash",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensTank",
        "mensSleeveless",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "sling",
    "name": "Sling",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensTank",
        "mensSleeveless",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "beachcomber",
    "name": "Beachcomber",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensTank",
        "mensSleeveless",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "bowie",
    "name": "Bowie",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "chevron",
    "name": "Chevron",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "racer",
    "name": "Racer",
    "colors": 1,
    "limitations": {
      "productIds": [
        "mensTank",
        "mensSleeveless",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "armor",
    "name": "Armor",
    "colors": 1,
    "limitations": {
      "productIds": [
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "digi",
    "name": "Digi",
    "colors": 1,
    "limitations": {
      "productIds": [
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "spotlight",
    "name": "Spotlight",
    "colors": 1,
    "limitations": {
      "productIds": [
        "womensLongSleeve"
      ]
    }
  },
  {
    "id": "fullCustom",
    "name": "Full Custom",
    "colors": 0,
    "limitations": {
      "productIds": [
        "mensShortSleeve",
        "mensTank",
        "mensSleeveless",
        "womensShortSleeve",
        "womensTank",
        "womensLongSleeve"
      ]
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DesignRow[]>> = data;

export type Design = typeof typed[number];

export const DESIGN_INDEX_KEY = "id";
export type DesignIndexKey = "id";
export type DesignId = Design["id"];

let i = 0;
export const DESIGN_DICT = {
  "freeze": typed[i++],
  "nails": typed[i++],
  "nitro": typed[i++],
  "turbo": typed[i++],
  "athena": typed[i++],
  "attack": typed[i++],
  "champ": typed[i++],
  "rebel": typed[i++],
  "socal": typed[i++],
  "redondo": typed[i++],
  "impact": typed[i++],
  "laser": typed[i++],
  "malibu": typed[i++],
  "titan": typed[i++],
  "blaze": typed[i++],
  "beach": typed[i++],
  "hermosa": typed[i++],
  "elevate": typed[i++],
  "watersprings_alt": typed[i++],
  "ace": typed[i++],
  "wave": typed[i++],
  "diamond": typed[i++],
  "sash": typed[i++],
  "sling": typed[i++],
  "beachcomber": typed[i++],
  "bowie": typed[i++],
  "chevron": typed[i++],
  "racer": typed[i++],
  "armor": typed[i++],
  "digi": typed[i++],
  "spotlight": typed[i++],
  "fullCustom": typed[i++]
} as const;

export { typed as DESIGNS };
