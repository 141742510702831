// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ColorRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "black",
    "name": "black",
    "hex": "#000000"
  },
  {
    "id": "coolGray11C",
    "name": "Cool Gray 11C",
    "hex": "#54565a"
  },
  {
    "id": "coolGray8C",
    "name": "Cool Gray 8C",
    "hex": "#898a8d"
  },
  {
    "id": "coolGray5C",
    "name": "Cool Gray 5C",
    "hex": "#b1b1b1"
  },
  {
    "id": "coolGray3C",
    "name": "Cool Gray 3C",
    "hex": "#c8c8c8"
  },
  {
    "id": "white",
    "name": "white",
    "hex": "#ffffff"
  },
  {
    "id": "2758C",
    "name": "2758C",
    "hex": "#001e61"
  },
  {
    "id": "282C",
    "name": "282C",
    "hex": "#011e41"
  },
  {
    "id": "blue072C",
    "name": "Blue 072C",
    "hex": "#000f9f"
  },
  {
    "id": "286C",
    "name": "286C",
    "hex": "#0033a1"
  },
  {
    "id": "319C",
    "name": "319C",
    "hex": "#1ecad3"
  },
  {
    "id": "278C",
    "name": "278C",
    "hex": "#8ab7e9"
  },
  {
    "id": "542C",
    "name": "542C",
    "hex": "#7badd3"
  },
  {
    "id": "292C",
    "name": "292C",
    "hex": "#65b2e8"
  },
  {
    "id": "312C",
    "name": "312C",
    "hex": "#00a6ce"
  },
  {
    "id": "310C",
    "name": "310C",
    "hex": "#63cfe3"
  },
  {
    "id": "314C",
    "name": "314C",
    "hex": "#007da5"
  },
  {
    "id": "186C",
    "name": "186C",
    "hex": "#ce0e2d"
  },
  {
    "id": "208C",
    "name": "208C",
    "hex": "#8a1e41"
  },
  {
    "id": "187C",
    "name": "187C",
    "hex": "#aa182c"
  },
  {
    "id": "806C",
    "name": "806C",
    "hex": "#ff40b4"
  },
  {
    "id": "225C",
    "name": "225C",
    "hex": "#e31d93"
  },
  {
    "id": "211C",
    "name": "211C",
    "hex": "#f97fb5"
  },
  {
    "id": "189C",
    "name": "189C",
    "hex": "#fca3bb"
  },
  {
    "id": "190C",
    "name": "190C",
    "hex": "#fa7598"
  },
  {
    "id": "268C",
    "name": "268C",
    "hex": "#592c82"
  },
  {
    "id": "violetC",
    "name": "Violet C",
    "hex": "#410099"
  },
  {
    "id": "2695C",
    "name": "2695C",
    "hex": "#2e1a46"
  },
  {
    "id": "7676C",
    "name": "7676C",
    "hex": "#7564a0"
  },
  {
    "id": "252C",
    "name": "252C",
    "hex": "#cf63ce"
  },
  {
    "id": "2655C",
    "name": "2655C",
    "hex": "#9578d3"
  },
  {
    "id": "2716C",
    "name": "2716C",
    "hex": "#9eade5"
  },
  {
    "id": "269C",
    "name": "269C",
    "hex": "#522d6d"
  },
  {
    "id": "7484C",
    "name": "7484C",
    "hex": "#005640"
  },
  {
    "id": "7739C",
    "name": "7739C",
    "hex": "#2c9942"
  },
  {
    "id": "360C",
    "name": "360C",
    "hex": "#6abf4b"
  },
  {
    "id": "375C",
    "name": "375C",
    "hex": "#93d500"
  },
  {
    "id": "378C",
    "name": "378C",
    "hex": "#59611d"
  },
  {
    "id": "7548C",
    "name": "7548C",
    "hex": "#ffc600"
  },
  {
    "id": "yellowC",
    "name": "Yellow C",
    "hex": "#ffdd00"
  },
  {
    "id": "803C",
    "name": "803C",
    "hex": "#ffe800"
  },
  {
    "id": "1495C",
    "name": "1495C",
    "hex": "#ff9015"
  },
  {
    "id": "orange021C",
    "name": "Orange 021C",
    "hex": "#ff5100"
  },
  {
    "id": "151C",
    "name": "151C",
    "hex": "#ff8300"
  },
  {
    "id": "804C",
    "name": "804C",
    "hex": "#ffab4d"
  },
  {
    "id": "159C",
    "name": "159C",
    "hex": "#d15e14"
  },
  {
    "id": "4695C",
    "name": "4695C",
    "hex": "#5c3327"
  },
  {
    "id": "4705C",
    "name": "4705C",
    "hex": "#804c3b"
  },
  {
    "id": "4525C",
    "name": "4525C",
    "hex": "#c6b784"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ColorRow[]>> = data;

export type Color = typeof typed[number];

export const COLOR_INDEX_KEY = "id";
export type ColorIndexKey = "id";
export type ColorId = Color["id"];

let i = 0;
export const COLOR_DICT = {
  "black": typed[i++],
  "coolGray11C": typed[i++],
  "coolGray8C": typed[i++],
  "coolGray5C": typed[i++],
  "coolGray3C": typed[i++],
  "white": typed[i++],
  "2758C": typed[i++],
  "282C": typed[i++],
  "blue072C": typed[i++],
  "286C": typed[i++],
  "319C": typed[i++],
  "278C": typed[i++],
  "542C": typed[i++],
  "292C": typed[i++],
  "312C": typed[i++],
  "310C": typed[i++],
  "314C": typed[i++],
  "186C": typed[i++],
  "208C": typed[i++],
  "187C": typed[i++],
  "806C": typed[i++],
  "225C": typed[i++],
  "211C": typed[i++],
  "189C": typed[i++],
  "190C": typed[i++],
  "268C": typed[i++],
  "violetC": typed[i++],
  "2695C": typed[i++],
  "7676C": typed[i++],
  "252C": typed[i++],
  "2655C": typed[i++],
  "2716C": typed[i++],
  "269C": typed[i++],
  "7484C": typed[i++],
  "7739C": typed[i++],
  "360C": typed[i++],
  "375C": typed[i++],
  "378C": typed[i++],
  "7548C": typed[i++],
  "yellowC": typed[i++],
  "803C": typed[i++],
  "1495C": typed[i++],
  "orange021C": typed[i++],
  "151C": typed[i++],
  "804C": typed[i++],
  "159C": typed[i++],
  "4695C": typed[i++],
  "4705C": typed[i++],
  "4525C": typed[i++]
} as const;

export { typed as COLORS };
