// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { DesignPartRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "designId": "freeze",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/freeze_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "39.37",
    "translateY": "-200.00",
    "scale": "11.81",
    "maskParts": [
      "armLeftBottom ~ leftBottom : in2px(1.57)"
    ]
  },
  {
    "designId": "freeze",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/freeze_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-39.37",
    "translateY": "-200.00",
    "scale": "11.81",
    "maskParts": [
      "rightBottom ~ armRightBottom : in2px(1.57)"
    ]
  },
  {
    "designId": "freeze",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/freeze_topRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightTop",
    "translateY": "-114.17",
    "scale": "11.81"
  },
  {
    "designId": "freeze",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/freeze_topRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armLeftTop",
    "translateY": "-114.17",
    "scale": "11.81"
  },
  {
    "designId": "freeze",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/freeze_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armLeftBottom",
    "translateX": "39.37",
    "translateY": "-200.00",
    "scale": "11.81",
    "maskParts": [
      "armRightBottom ~ rightBottom : in2px(1.57)"
    ]
  },
  {
    "designId": "freeze",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/freeze_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armRightBottom",
    "translateX": "-39.37",
    "translateY": "-200.00",
    "scale": "11.81",
    "maskParts": [
      "leftBottom ~ armLeftBottom : in2px(1.57)"
    ]
  },
  {
    "designId": "freeze",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/freeze_topRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armLeftTop",
    "translateY": "-149.61",
    "scale": "11.81"
  },
  {
    "designId": "freeze",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/freeze_topRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armRightTop",
    "translateY": "-149.61",
    "scale": "11.81"
  },
  {
    "designId": "freeze",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/freeze_sleeve",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "scale": "7.87"
  },
  {
    "designId": "freeze",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/freeze_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "scale": "7.87"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "front",
    "productIdGlob": "!*Tank",
    "assetName": "designs/nails_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-196.85",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/nails_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-78.74",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "front",
    "productIdGlob": "mensTank",
    "assetName": "designs/nails_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-196.85",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "front",
    "productIdGlob": "!*Tank",
    "assetName": "designs/nails_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "196.85",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/nails_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "78.74",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "front",
    "productIdGlob": "mensTank",
    "assetName": "designs/nails_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "196.85",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "back",
    "productIdGlob": "!*Tank",
    "assetName": "designs/nails_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "196.85",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/nails_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "78.74",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "back",
    "productIdGlob": "mensTank",
    "assetName": "designs/nails_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "196.85",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "back",
    "productIdGlob": "!*Tank",
    "assetName": "designs/nails_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-196.85",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/nails_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-78.74",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "back",
    "productIdGlob": "mensTank",
    "assetName": "designs/nails_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-196.85",
    "scale": "9.84"
  },
  {
    "designId": "nails",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "*",
    "assetName": "designs/nails_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "scale": "7.87"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "front",
    "productIdGlob": "!womensTank",
    "assetName": "designs/nitro_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "9.84"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/nitro_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "551.18",
    "scale": "9.84"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "front",
    "productIdGlob": "!womensTank",
    "assetName": "designs/nitro_topLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-left",
    "anchorTarget": "neckLeft",
    "translateX": "-196.85",
    "translateY": "-236.22",
    "scale": "9.84"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/nitro_topLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-left",
    "anchorTarget": "neckLeft",
    "translateX": "-196.85",
    "translateY": "-236.22",
    "scale": "9.84"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "front",
    "productIdGlob": "!womensTank",
    "assetName": "designs/nitro_topRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-right",
    "anchorTarget": "neckRight",
    "translateX": "196.85",
    "translateY": "-236.22",
    "scale": "9.84"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/nitro_topRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-right",
    "anchorTarget": "neckRight",
    "translateX": "196.85",
    "translateY": "-236.22",
    "scale": "9.84"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "back",
    "productIdGlob": "!womensTank",
    "assetName": "designs/nitro_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "9.84"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/nitro_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "551.18",
    "scale": "9.84"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/nitro_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "scale": "7.87"
  },
  {
    "designId": "nitro",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/nitro_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "scale": "7.87"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "front",
    "productIdGlob": "mensShortSleeve",
    "assetName": "designs/turbo_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "118.11",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "front",
    "productIdGlob": "mensTank",
    "assetName": "designs/turbo_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "39.37",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "front",
    "productIdGlob": "mensSleeveless",
    "assetName": "designs/turbo_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "157.48",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "front",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/turbo_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "196.85",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/turbo_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "275.59",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/turbo_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "back",
    "productIdGlob": "mensShortSleeve",
    "assetName": "designs/turbo_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "118.11",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "back",
    "productIdGlob": "mensTank",
    "assetName": "designs/turbo_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "39.37",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "back",
    "productIdGlob": "mensSleeveless",
    "assetName": "designs/turbo_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "157.48",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "back",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/turbo_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "196.85",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/turbo_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "275.59",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/turbo_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "scale": "9.84"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/turbo_sleeve",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "turbo",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/turbo_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "front",
    "productIdGlob": "mensShortSleeve",
    "assetName": "designs/athena_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "-157.48",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "front",
    "productIdGlob": "mensTank",
    "assetName": "designs/athena_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "-236.22",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "front",
    "productIdGlob": "mensSleeveless",
    "assetName": "designs/athena_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "-118.11",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "front",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/athena_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "-78.74",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/athena_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/athena_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "back",
    "productIdGlob": "mensShortSleeve",
    "assetName": "designs/athena_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "-157.48",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "back",
    "productIdGlob": "mensTank",
    "assetName": "designs/athena_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "-236.22",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "back",
    "productIdGlob": "mensSleeveless",
    "assetName": "designs/athena_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "-118.11",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "back",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/athena_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "-78.74",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/athena_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/athena_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "scale": "9.84"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/athena_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "athena",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/athena_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "attack",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/attack_sleeve",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "attack",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/attack_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "champ",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/champ_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armLeftBottom ~ leftBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "champ",
    "pieceNameGlob": "front",
    "productIdGlob": "womens*",
    "assetName": "designs/champ_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armLeftBottom ~ leftBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "champ",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/champ_frontRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "rightBottom ~ armRightBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "champ",
    "pieceNameGlob": "front",
    "productIdGlob": "womens*",
    "assetName": "designs/champ_frontRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "rightBottom ~ armRightBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "champ",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/champ_topRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightTop",
    "translateY": "-114.17",
    "scale": "11.81"
  },
  {
    "designId": "champ",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/champ_topRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armLeftTop",
    "translateY": "-114.17",
    "scale": "11.81"
  },
  {
    "designId": "champ",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/champ_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armRightBottom ~ rightBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "champ",
    "pieceNameGlob": "back",
    "productIdGlob": "womens*",
    "assetName": "designs/champ_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armRightBottom ~ rightBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "champ",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/champ_frontRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "leftBottom ~ armLeftBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "champ",
    "pieceNameGlob": "back",
    "productIdGlob": "womens*",
    "assetName": "designs/champ_frontRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "leftBottom ~ armLeftBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "champ",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/champ_topRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armLeftTop",
    "translateY": "-149.61",
    "scale": "11.81"
  },
  {
    "designId": "champ",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/champ_topRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armRightTop",
    "translateY": "-149.61",
    "scale": "11.81"
  },
  {
    "designId": "rebel",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/rebel_left",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-39.37",
    "scale": "9.84"
  },
  {
    "designId": "rebel",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/rebel_left",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "39.37",
    "scale": "9.84"
  },
  {
    "designId": "rebel",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/rebel_left",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-39.37",
    "scale": "9.84"
  },
  {
    "designId": "rebel",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/rebel_left",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "39.37",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/socal_frontLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "250.00",
    "translateY": "-1100.00",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "front",
    "productIdGlob": "womens{Short,Long}Sleeve",
    "assetName": "designs/socal_frontLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "137.80",
    "translateY": "-1200.00",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/socal_frontLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "137.80",
    "translateY": "-1181.10",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/socal_frontLeft",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-250.00",
    "translateY": "-1100.00",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "front",
    "productIdGlob": "womens{Short,Long}Sleeve",
    "assetName": "designs/socal_frontLeft",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-137.80",
    "translateY": "-1200.00",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/socal_frontLeft",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-137.80",
    "translateY": "-1181.10",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/socal_backLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-250.00",
    "translateY": "-1181.10",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "back",
    "productIdGlob": "womens{Short,Long}Sleeve",
    "assetName": "designs/socal_backLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-137.80",
    "translateY": "-1259.84",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/socal_backLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-137.80",
    "translateY": "-1181.10",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/socal_backLeft",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "250.00",
    "translateY": "-1181.10",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "back",
    "productIdGlob": "womens{Short,Long}Sleeve",
    "assetName": "designs/socal_backLeft",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "137.80",
    "translateY": "-1259.84",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/socal_backLeft",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "137.80",
    "translateY": "-1181.10",
    "scale": "9.84"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/socal_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "socal",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/socal_sleeve",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "redondo",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/redondo_left",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-39.37",
    "translateY": "-905.51",
    "scale": "9.84"
  },
  {
    "designId": "redondo",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/redondo_left",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "39.37",
    "translateY": "-905.51",
    "scale": "9.84"
  },
  {
    "designId": "redondo",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/redondo_left",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-39.37",
    "translateY": "-905.51",
    "scale": "9.84"
  },
  {
    "designId": "redondo",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/redondo_left",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "39.37",
    "translateY": "-905.51",
    "scale": "9.84"
  },
  {
    "designId": "impact",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/impact_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "393.70",
    "scale": "8.66"
  },
  {
    "designId": "impact",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/impact_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "393.70",
    "scale": "8.66"
  },
  {
    "designId": "laser",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/laser_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "9.84"
  },
  {
    "designId": "laser",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/laser_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "9.84"
  },
  {
    "designId": "laser",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/laser_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "7.09"
  },
  {
    "designId": "malibu",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/malibu_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightBottom",
    "translateX": "157.48",
    "translateY": "1181.10",
    "scale": "11.81",
    "maskParts": [
      "armLeftBottom ~ leftBottom : -in2px(1.75)",
      "rightBottom ~ armRightBottom : -in2px(1.75)"
    ]
  },
  {
    "designId": "malibu",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/malibu_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armLeftBottom",
    "translateX": "-157.48",
    "translateY": "1181.10",
    "scale": "11.81",
    "maskParts": [
      "armRightBottom ~ rightBottom : -in2px(1.75)",
      "leftBottom ~ armLeftBottom : -in2px(1.75)"
    ]
  },
  {
    "designId": "malibu",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/freeze_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "100.00",
    "maskParts": [
      "armLeftBottom#in2px(1) ~ leftBottom : in2px(2)",
      "armLeftBottom#in2px(1) ~ leftBottom : -in2px(1.75)",
      "rightBottom ~ armRightBottom#in2px(1) : in2px(2)",
      "rightBottom ~ armRightBottom#in2px(1) : -in2px(1.75)",
      "neckLeft ~ armLeftTop : in2px(2.5)",
      "neckLeft ~ armLeftTop : -in2px(2.25)",
      "armRightTop ~ neckRight : in2px(2.5)",
      "armRightTop ~ neckRight : -in2px(2.25)"
    ]
  },
  {
    "designId": "malibu",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/freeze_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "100.00",
    "maskParts": [
      "armRightBottom#in2px(1) ~ rightBottom : in2px(2)",
      "armRightBottom#in2px(1) ~ rightBottom : -in2px(1.75)",
      "leftBottom ~ armLeftBottom#in2px(1) : in2px(2)",
      "leftBottom ~ armLeftBottom#in2px(1) : -in2px(1.75)"
    ]
  },
  {
    "designId": "beach",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/beach_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightBottom",
    "translateX": "157.48",
    "translateY": "1181.10",
    "scale": "11.81",
    "maskParts": [
      "armLeftBottom ~ leftBottom : -in2px(1.75)",
      "rightBottom ~ armRightBottom : -in2px(1.75)"
    ]
  },
  {
    "designId": "beach",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/beach_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armLeftBottom",
    "translateX": "-157.48",
    "translateY": "1181.10",
    "scale": "11.81",
    "maskParts": [
      "armRightBottom ~ rightBottom : -in2px(1.75)",
      "leftBottom ~ armLeftBottom : -in2px(1.75)"
    ]
  },
  {
    "designId": "beach",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/freeze_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "100.00",
    "maskParts": [
      "armLeftBottom#in2px(1) ~ leftBottom : in2px(2)",
      "armLeftBottom#in2px(1) ~ leftBottom : -in2px(1.75)",
      "rightBottom ~ armRightBottom#in2px(1) : in2px(2)",
      "rightBottom ~ armRightBottom#in2px(1) : -in2px(1.75)",
      "neckLeft ~ armLeftTop : in2px(2.5)",
      "neckLeft ~ armLeftTop : -in2px(2.25)",
      "armRightTop ~ neckRight : in2px(2.5)",
      "armRightTop ~ neckRight : -in2px(2.25)"
    ]
  },
  {
    "designId": "beach",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/freeze_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "100.00",
    "maskParts": [
      "armRightBottom#in2px(1) ~ rightBottom : in2px(2)",
      "armRightBottom#in2px(1) ~ rightBottom : -in2px(1.75)",
      "leftBottom ~ armLeftBottom#in2px(1) : in2px(2)",
      "leftBottom ~ armLeftBottom#in2px(1) : -in2px(1.75)"
    ]
  },
  {
    "designId": "titan",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/titan_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "9.84"
  },
  {
    "designId": "titan",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/titan_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "9.84"
  },
  {
    "designId": "blaze",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/blaze_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "9.84"
  },
  {
    "designId": "blaze",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/blaze_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "9.84"
  },
  {
    "designId": "blaze",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/blaze_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "blaze",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/blaze_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/hermosa_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-left",
    "anchorTarget": "armRightBottom",
    "translateY": "-1181.10",
    "scale": "9.45"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "mensTank",
    "assetName": "designs/hermosa_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-left",
    "anchorTarget": "armRightBottom",
    "translateY": "-1181.10",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/hermosa_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-left",
    "anchorTarget": "armRightBottom",
    "translateY": "-866.14",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/hermosa_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-left",
    "anchorTarget": "armRightBottom",
    "translateY": "-866.14",
    "scale": "8.27"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/hermosa_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-left",
    "anchorTarget": "armRightBottom",
    "translateY": "-787.40",
    "scale": "7.87"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/hermosa_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-78.74",
    "translateY": "-59.06",
    "scale": "9.45"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "{mensTank,womensShortSleeve}",
    "assetName": "designs/hermosa_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-59.06",
    "translateY": "-59.06",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/hermosa_sideWomensTank",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "59.06",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/hermosa_sideWomensLongSleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "78.74",
    "scale": "7.09"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/hermosa_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "78.74",
    "translateY": "-59.06",
    "scale": "9.45"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "{mensTank,womensShortSleeve}",
    "assetName": "designs/hermosa_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "59.06",
    "translateY": "-59.06",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/hermosa_sideWomensTank",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-59.06",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/hermosa_sideWomensLongSleeve",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "78.74",
    "scale": "7.09"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/hermosa_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-right",
    "anchorTarget": "armRightBottom",
    "translateY": "-1181.10",
    "scale": "9.45"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "mensTank",
    "assetName": "designs/hermosa_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-right",
    "anchorTarget": "armRightBottom",
    "translateY": "-1181.10",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/hermosa_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-right",
    "anchorTarget": "armRightBottom",
    "translateY": "-866.14",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/hermosa_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-right",
    "anchorTarget": "armRightBottom",
    "translateY": "-866.14",
    "scale": "8.27"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/hermosa_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-right",
    "anchorTarget": "armRightBottom",
    "translateY": "-787.40",
    "scale": "7.87"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/hermosa_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "78.74",
    "translateY": "-59.06",
    "scale": "9.45"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "{mensTank,womensShortSleeve}",
    "assetName": "designs/hermosa_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "59.06",
    "translateY": "-59.06",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/hermosa_sideWomensTank",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-59.06",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/hermosa_sideWomensLongSleeve",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "78.74",
    "scale": "7.09"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/hermosa_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-78.74",
    "translateY": "-59.06",
    "scale": "9.45"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "{mensTank,womensShortSleeve}",
    "assetName": "designs/hermosa_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-59.06",
    "translateY": "-59.06",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/hermosa_sideWomensTank",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "59.06",
    "scale": "8.66"
  },
  {
    "designId": "hermosa",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/hermosa_sideWomensLongSleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "78.74",
    "scale": "7.09"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-600.00",
    "translateY": "-1100.00",
    "scale": "8.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "mensTank",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-800.00",
    "translateY": "-1100.00",
    "scale": "8.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-400.00",
    "translateY": "-800.00",
    "scale": "8.00"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-400.00",
    "translateY": "-600.00",
    "scale": "7.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-400.00",
    "translateY": "-700.00",
    "scale": "7.00"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "600.00",
    "translateY": "-1100.00",
    "scale": "8.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "mensTank",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "800.00",
    "translateY": "-1100.00",
    "scale": "8.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "400.00",
    "translateY": "-800.00",
    "scale": "8.00"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "400.00",
    "translateY": "-600.00",
    "scale": "7.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "400.00",
    "translateY": "-700.00",
    "scale": "7.00"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "600.00",
    "translateY": "-1100.00",
    "scale": "8.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "mensTank",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "800.00",
    "translateY": "-1100.00",
    "scale": "8.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "400.00",
    "translateY": "-800.00",
    "scale": "8.00"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "400.00",
    "translateY": "-600.00",
    "scale": "7.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/elevate_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "400.00",
    "translateY": "-700.00",
    "scale": "7.00"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "mens{ShortSleeve,Sleeveless}",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-600.00",
    "translateY": "-1100.00",
    "scale": "8.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "mensTank",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-800.00",
    "translateY": "-1100.00",
    "scale": "8.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-400.00",
    "translateY": "-800.00",
    "scale": "8.00"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-400.00",
    "translateY": "-600.00",
    "scale": "7.50"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/elevate_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-400.00",
    "translateY": "-700.00",
    "scale": "7.00"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "{mens*,womensTank}",
    "assetName": "designs/elevate_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/elevate_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "8.00"
  },
  {
    "designId": "elevate",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/elevate_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-200.00",
    "scale": "7.00"
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/watersprings_alt_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armLeftBottom ~ leftBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "front",
    "productIdGlob": "womens*",
    "assetName": "designs/watersprings_alt_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armLeftBottom ~ leftBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/watersprings_alt_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "rightBottom ~ armRightBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "front",
    "productIdGlob": "womens*",
    "assetName": "designs/watersprings_alt_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "rightBottom ~ armRightBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/watersprings_alt_frontTop",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-500.00",
    "scale": "11.81"
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "front",
    "productIdGlob": "womens{Short,Long}Sleeve",
    "assetName": "designs/watersprings_alt_frontTop",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-650.00",
    "scale": "11.81"
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/watersprings_alt_frontTop",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-800.00",
    "scale": "11.81"
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/watersprings_alt_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armRightBottom ~ rightBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "back",
    "productIdGlob": "womens*",
    "assetName": "designs/watersprings_alt_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armRightBottom ~ rightBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/watersprings_alt_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "leftBottom ~ armLeftBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "back",
    "productIdGlob": "womens*",
    "assetName": "designs/watersprings_alt_right",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "leftBottom ~ armLeftBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/watersprings_alt_backTop",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-300.00",
    "scale": "11.81"
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "back",
    "productIdGlob": "womens*",
    "assetName": "designs/watersprings_alt_backTop",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-450.00",
    "scale": "11.81"
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "{mens*,womensTank}",
    "assetName": "designs/watersprings_alt_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "7.87"
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/watersprings_alt_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "scale": "8.00"
  },
  {
    "designId": "watersprings_alt",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/watersprings_alt_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "7.00"
  },
  {
    "designId": "ace",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/ace_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armLeftBottom ~ leftBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "ace",
    "pieceNameGlob": "front",
    "productIdGlob": "womens*",
    "assetName": "designs/ace_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armLeftBottom ~ leftBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "ace",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/ace_frontRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "rightBottom ~ armRightBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "ace",
    "pieceNameGlob": "front",
    "productIdGlob": "womens*",
    "assetName": "designs/ace_frontRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "rightBottom ~ armRightBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "ace",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/ace_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armRightBottom ~ rightBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "ace",
    "pieceNameGlob": "back",
    "productIdGlob": "womens*",
    "assetName": "designs/ace_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "armRightBottom ~ rightBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "ace",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/ace_frontRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "leftBottom ~ armLeftBottom : in2px(3.93)"
    ]
  },
  {
    "designId": "ace",
    "pieceNameGlob": "back",
    "productIdGlob": "womens*",
    "assetName": "designs/ace_frontRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "196.85",
    "scale": "11.81",
    "maskParts": [
      "leftBottom ~ armLeftBottom : in2px(2.36)"
    ]
  },
  {
    "designId": "ace",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "{mens*,womensTank}",
    "assetName": "designs/ace_shortSleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "25.00",
    "scale": "7.87"
  },
  {
    "designId": "ace",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/ace_shortSleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "25.00",
    "scale": "8.00"
  },
  {
    "designId": "ace",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/ace_longSleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "7.00"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "front",
    "productIdGlob": "{mens*,womensShortSleeve}",
    "assetName": "designs/wave_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-50.00",
    "translateY": "-450.00",
    "scale": "7.50"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/wave_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "-450.00",
    "scale": "7.50"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/wave_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-60.00",
    "translateY": "-450.00",
    "scale": "6.40"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "front",
    "productIdGlob": "{mens*,womensShortSleeve}",
    "assetName": "designs/wave_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "50.00",
    "translateY": "-450.00",
    "scale": "7.50"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/wave_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "-450.00",
    "scale": "7.50"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/wave_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "60.00",
    "translateY": "-450.00",
    "scale": "6.40"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "back",
    "productIdGlob": "{mens*,womensShortSleeve}",
    "assetName": "designs/wave_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateY": "-450.00",
    "scale": "7.50"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/wave_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "50.00",
    "translateY": "-450.00",
    "scale": "7.50"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/wave_side",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-160.00",
    "translateY": "-460.00",
    "scale": "6.40"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "back",
    "productIdGlob": "{mens*,womensShortSleeve}",
    "assetName": "designs/wave_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateY": "-450.00",
    "scale": "7.50"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/wave_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-50.00",
    "translateY": "-450.00",
    "scale": "7.50"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/wave_side",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "160.00",
    "translateY": "-460.00",
    "scale": "6.40"
  },
  {
    "designId": "wave",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/wave_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "6.40"
  },
  {
    "designId": "diamond",
    "pieceNameGlob": "front",
    "productIdGlob": "{mens*,womensLongSleeve}",
    "assetName": "designs/diamond_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "8.00"
  },
  {
    "designId": "diamond",
    "pieceNameGlob": "front",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/diamond_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "550.00",
    "scale": "8.80"
  },
  {
    "designId": "diamond",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/diamond_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "900.00",
    "scale": "6.80"
  },
  {
    "designId": "diamond",
    "pieceNameGlob": "back",
    "productIdGlob": "{mens*,womensLongSleeve}",
    "assetName": "designs/diamond_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "472.44",
    "scale": "8.00"
  },
  {
    "designId": "diamond",
    "pieceNameGlob": "back",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/diamond_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "550.00",
    "scale": "8.80"
  },
  {
    "designId": "diamond",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/diamond_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "910.00",
    "scale": "6.80"
  },
  {
    "designId": "diamond",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/diamond_sleeve",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "8.00"
  },
  {
    "designId": "diamond",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/diamond_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "8.00"
  },
  {
    "designId": "sash",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/sash_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "700.00",
    "scale": "8.00"
  },
  {
    "designId": "sash",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/sash_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "700.00",
    "scale": "8.00"
  },
  {
    "designId": "sash",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/sash_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "6.40"
  },
  {
    "designId": "sash",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/sash_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "6.40"
  },
  {
    "designId": "sling",
    "pieceNameGlob": "front",
    "productIdGlob": "{mens*,womensShortSleeve}",
    "assetName": "designs/sling_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "300.00",
    "scale": "9.00"
  },
  {
    "designId": "sling",
    "pieceNameGlob": "front",
    "productIdGlob": "womens{Tank,LongSleeve}",
    "assetName": "designs/sling_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "500.00",
    "scale": "9.00"
  },
  {
    "designId": "sling",
    "pieceNameGlob": "back",
    "productIdGlob": "{mens*,womensShortSleeve}",
    "assetName": "designs/sling_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "300.00",
    "scale": "9.00"
  },
  {
    "designId": "sling",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/sling_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "510.00",
    "scale": "9.00"
  },
  {
    "designId": "sling",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/sling_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "500.00",
    "scale": "9.00"
  },
  {
    "designId": "sling",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/sling_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "9.00"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "front",
    "productIdGlob": "mensTank",
    "assetName": "designs/beachcomber_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "700.00",
    "scale": "9.00"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "front",
    "productIdGlob": "mensSleeveless",
    "assetName": "designs/beachcomber_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "650.00",
    "scale": "9.00"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/beachcomber_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "700.00",
    "scale": "7.00"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/beachcomber_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "600.00",
    "scale": "7.00"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "back",
    "productIdGlob": "mensTank",
    "assetName": "designs/beachcomber_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "700.00",
    "scale": "9.00"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "back",
    "productIdGlob": "mensSleeveless",
    "assetName": "designs/beachcomber_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "650.00",
    "scale": "9.00"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/beachcomber_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "700.00",
    "scale": "7.00"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/beachcomber_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "600.00",
    "scale": "7.00"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/beachcomber_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "6.40"
  },
  {
    "designId": "beachcomber",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/beachcomber_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "200.00",
    "scale": "6.40"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "front",
    "productIdGlob": "{mens*,womensShortSleeve}",
    "assetName": "designs/bowie_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "450.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/bowie_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "500.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/bowie_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateX": "100.00",
    "translateY": "300.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "front",
    "productIdGlob": "mensShortSleeve",
    "assetName": "designs/bowie_frontTop",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightTop",
    "translateX": "100.00",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "front",
    "productIdGlob": "*Tank",
    "assetName": "designs/bowie_frontTop",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightTop",
    "translateX": "-100.00",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "front",
    "productIdGlob": "{mensSleeveless,womensLongSleeve}",
    "assetName": "designs/bowie_frontTop",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightTop",
    "translateX": "-50.00",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "front",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/bowie_frontTop",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightTop",
    "translateX": "0.00",
    "translateY": "25.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "back",
    "productIdGlob": "{mens*,womensShortSleeve}",
    "assetName": "designs/bowie_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "450.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/bowie_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateY": "500.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/bowie_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorTarget": "armBottomMiddle",
    "translateX": "-100.00",
    "translateY": "300.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "back",
    "productIdGlob": "mensShortSleeve",
    "assetName": "designs/bowie_frontTop",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armRightTop",
    "translateX": "-100.00",
    "translateY": "25.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "back",
    "productIdGlob": "*Tank",
    "assetName": "designs/bowie_frontTop",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armRightTop",
    "translateX": "100.00",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "back",
    "productIdGlob": "{mensSleeveless,womensLongSleeve}",
    "assetName": "designs/bowie_frontTop",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armRightTop",
    "translateX": "50.00",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "back",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/bowie_frontTop",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armRightTop",
    "translateX": "0.00",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/bowie_sleeveRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "300.00",
    "scale": "7.90"
  },
  {
    "designId": "bowie",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/bowie_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "300.00",
    "scale": "7.90"
  },
  {
    "designId": "chevron",
    "pieceNameGlob": "front",
    "productIdGlob": "mens*",
    "assetName": "designs/chevron_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "100.00",
    "scale": "8.50"
  },
  {
    "designId": "chevron",
    "pieceNameGlob": "front",
    "productIdGlob": "womens*",
    "assetName": "designs/chevron_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "70.00",
    "scale": "7.90"
  },
  {
    "designId": "chevron",
    "pieceNameGlob": "back",
    "productIdGlob": "mens*",
    "assetName": "designs/chevron_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "100.00",
    "scale": "8.50"
  },
  {
    "designId": "chevron",
    "pieceNameGlob": "back",
    "productIdGlob": "womensShortSleeve",
    "assetName": "designs/chevron_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "105.00",
    "scale": "7.90"
  },
  {
    "designId": "chevron",
    "pieceNameGlob": "back",
    "productIdGlob": "womensTank",
    "assetName": "designs/chevron_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "70.00",
    "scale": "7.90"
  },
  {
    "designId": "chevron",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/chevron_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "100.00",
    "scale": "7.90"
  },
  {
    "designId": "chevron",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/chevron_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "chevron",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/chevron_sleeve",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/racer_frontLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "-50.00",
    "translateY": "-1000.00",
    "scale": "7.90"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/racer_frontLeft",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "50.00",
    "translateY": "-1000.00",
    "scale": "7.90"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "front",
    "productIdGlob": "!womensTank",
    "assetName": "designs/racer_frontTopLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightTop",
    "translateX": "-300.00",
    "translateY": "150.00",
    "scale": "7.90"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/racer_frontTopLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-left",
    "anchorTarget": "armRightTop",
    "translateX": "-300.00",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "front",
    "productIdGlob": "!womensTank",
    "assetName": "designs/racer_frontTopLeft",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armLeftTop",
    "translateX": "300.00",
    "translateY": "150.00",
    "scale": "7.90"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "front",
    "productIdGlob": "womensTank",
    "assetName": "designs/racer_frontTopLeft",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-right",
    "anchorTarget": "armLeftTop",
    "translateX": "300.00",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/racer_backRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "50.00",
    "translateY": "-1000.00",
    "scale": "7.90"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/racer_backRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armRightBottom",
    "translateX": "-50.00",
    "translateY": "-1000.00",
    "scale": "7.90"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/racer_sleeveRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "25.00",
    "scale": "6.00"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/racer_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "-25.00",
    "scale": "6.00"
  },
  {
    "designId": "armor",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/armor_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "spreadX": "-30.00",
    "scale": "6.50"
  },
  {
    "designId": "armor",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/armor_back",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "spreadX": "85.00",
    "scale": "6.50"
  },
  {
    "designId": "armor",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/armor_sleeveRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "armor",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/armor_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "scale": "7.90"
  },
  {
    "designId": "digi",
    "pieceNameGlob": "front",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/digi_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "-75.00",
    "scale": "6.50"
  },
  {
    "designId": "digi",
    "pieceNameGlob": "back",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/digi_back",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "-60.00",
    "scale": "6.50"
  },
  {
    "designId": "digi",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/digi_sleeveRight",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "scale": "6.50"
  },
  {
    "designId": "digi",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/digi_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "scale": "6.50"
  },
  {
    "designId": "spotlight",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/spotlight_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "scale": "6.50"
  },
  {
    "designId": "spotlight",
    "pieceNameGlob": "sleeve_{left,right}",
    "productIdGlob": "womensLongSleeve",
    "assetName": "designs/spotlight_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "0.00",
    "scale": "4.00"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DesignPartRow[]>> = data;

export type DesignPart = typeof typed[number];

export { typed as DESIGN_PARTS };
